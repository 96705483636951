<template>
  <div v-if="store.contentData" class="overallpage supporters">
    <h2>{{getContentData('supporters').headline}}</h2>
    <span v-html="getContentData('supporters').pageContent"></span>
  </div>
</template>

<script>
import store from '@/store/store.js';

export default {
  name: 'Supporters',
  components: {
  },
  data() {
    return {
      store,
    };
  },
   methods: {
    getContentData: function (dataHandle) {
      var contentModel = null;
      console.log( store.contentData);
      for(var i = 0; i < this.store.contentData.length; i++){
        if(this.store.contentData[i].pageHandle == dataHandle){
          contentModel = this.store.contentData[i];
        }
      }
      return contentModel;
    },
  }
}
</script>

<style lang="scss">
.supporters{
  a {
    padding: 0px;
    padding-top: 8px;
    font-size: 14px;
  }
}
</style>
